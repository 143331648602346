import React, { useEffect } from "react";
import Slide from "@material-ui/core/Slide";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import TaxManagementRecentCases from "../TaxManagementRecentCases";
import SearchView from "../SearchView";
import { FormattedMessage } from "react-intl";
import { userActions } from "../actions";
import Grid from "@material-ui/core/Grid";
import TaxManagementCaseDetails from "../TaxManagementCaseDetails";
import TaxManagementRecentClient from "../TaxManagementRecentClient";
import TaxManagementClientDetails from "../TaxManagementClientDetails";
import useCheckSessionApi from "../SessionValidity";
import { authHeader } from '../helpers';
import { useSelector, useDispatch } from 'react-redux';
import Paper from "@material-ui/core/Paper";
import { set } from "lodash";
import { PropagateLoader } from 'react-spinners';
import Swal from 'sweetalert2';
import TextField from "@material-ui/core/TextField";
import { Multiselect } from 'react-widgets';
import "react-widgets/styles.css";
import Button from "@material-ui/core/Button";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from '@material-ui/core/Checkbox';
import Autocomplete from "@material-ui/lab/Autocomplete";
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import MinimizeIcon from '@material-ui/icons/Minimize';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Modal from '@material-ui/core/Modal';
import InputLabel from '@material-ui/core/InputLabel';
import { SketchPicker } from 'react-color';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import PropTypes from "prop-types";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";

import TableSortLabel from '@material-ui/core/TableSortLabel';

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  function stableSort(tagList, comparator) {
    const stabilizedThis = tagList && tagList.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }
  
  const headCells = [
    { id: 'tag_id', numeric: false, label: 'Tag Id', },
    { id: 'name', numeric: false, label: 'Name', },
    { id: 'shortcode', numeric: false, label: 'Short Code', },
    { id: 'color', numeric: false, label: 'Color', },
    { id: 'actions', numeric: false, label: 'Actions', },
  ];
  function EnhancedTableHead(props) {
    const { classes, onSelectAllClick, order, orderBy, rowCount, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };
  
    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            ( headCell.id === "actions")?
            (
              <TableCell
                key={headCell.id}
                align="center"
                style={{fontWeight: 'bold', fontSize: 17,  width: "1%"}}
              >
                {headCell.label}
              </TableCell>
            )
            :(
            <TableCell
              key={headCell.id}
              // align={headCell.numeric ? 'center' : 'center'}
              padding={headCell.disablePadding ? 'none' : 'default'}
              sortDirection={orderBy === headCell.id ? order : false}
              align="center"
              style={{fontWeight: 'bold', fontSize: 17,  width: "10%"}}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          )
          ))}
        </TableRow>
      </TableHead>
    );
  }
  EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };
  
  const useStyles1 = makeStyles((theme) => ({
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
    }
  }));
  
  function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onChangePage } = props;
  
    const handleFirstPageButtonClick = (event) => {
      onChangePage(event, 0);
    };
  
    const handleBackButtonClick = (event) => {
      onChangePage(event, page - 1);
    };
  
    const handleNextButtonClick = (event) => {
      onChangePage(event, page + 1);
    };
  
    const handleLastPageButtonClick = (event) => {
      onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
  
    return (
      <div className={classes.root}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
  }
  
  TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
  };


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  wrapper: {
    margin: "10%",
    marginRight: "5%",
    marginTop: "7%",
    marginBottom: "3%",
  },

  pageHeaderLabel: {
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "20px",
    lineHeight: "20px",
    color: "rgba(0, 0, 0, 0.64)",
    textAlign: "left",
  },
  pageHeaderContainer: {
    marginBottom: "5%",
  },
  showProcedureStyle: {
    position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)"
  },
  paper: {
    padding: theme.spacing(2),
    height: '100%',
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
  validation: {
    color: 'red',
    fontSize: 12,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow:'scroll',
    // transform: 'translate(-50%, -50%)',
    // top: '50%',
    // left: '50%',
  },
  paperModal: {
    position: 'absolute',
    width: 500,
    height: 650,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    // top:'2%',
    display:'block',
    // overflowY:'scroll',
  },
  addNewBusinessHeaderBackgroud: {
    display: 'flex',
    alignItems: 'center', 
    justifyContent: 'center',
    // backgroundColor: '#23c1f7',
    height:'10%',
  },
  modalBody: {
    padding: theme.spacing(2, 4, 3),  
    width:'100%', 
  },
  formControl: {
    // margin: theme.spacing(1),
    marginTop: '2%',
    width: '100%',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },

}));

export default function TaxManagementTags(props) {
  const classes = useStyles();
  const[ShowProcedure, setShowProcedure ] = React.useState(false);
  const [device_token, setdevice_token ] = React.useState(JSON.parse(localStorage.getItem('device_token')));
  const [session_id, setsession_id ] = React.useState(JSON.parse(localStorage.getItem('session_id')));
  const [business_id, setbusiness_id ] = React.useState(JSON.parse(localStorage.getItem('logedIn_users_businessId')));
  const [business_shortcode, setbusiness_shortcode ] = React.useState(JSON.parse(localStorage.getItem('logedIn_users_businessId')));
  const [Flag, setFlag] = React.useState(false);
  const [BusinessRole, setBusinessRole] = React.useState();
  const [tagList, settagList] = React.useState([]);

  const [open, setOpen] = React.useState(false);
  const [tagDetails, settagDetails] = React.useState({
      name: "",
      shortcode: "",
      is_deleted: false,
      color: "",
      business_id: JSON.parse(localStorage.getItem('selected_business_id')),
      device_token: JSON.parse(localStorage.getItem('device_token')),
      session_id: JSON.parse(localStorage.getItem('session_id')),
  });

  const [nameErr, setnameErr] = React.useState();
    // const [shortcodeErr, setshortcodeErr] = React.useState();
    const [isDeletedErr, setisDeletedErr] = React.useState();
    const [colorErr, setcolorErr] = React.useState();
    const [EditTag, setEditTag] = React.useState(false);

  // redux
  const dispatch = useDispatch();

  let user_permissions = JSON.parse(localStorage.getItem('user_permissions'))
  // console.log("user_permissions from local storage on users page::::::",user_permissions)

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(100);
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [selected, setSelected] = React.useState([]);
    const [dense, setDense] = React.useState(false);

    const [paginationDetails, setpaginationDetails] = React.useState({total_items:0});
    const [totalNumberOfPagesFromApi, settotalNumberOfPagesFromApi] = React.useState();
    const [recentPageNumberFromApi, setrecentPageNumberFromApi] = React.useState();
    const [ShowTableFlag, setShowTableFlag] = React.useState(true);

    // sort
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, tagList && tagList.length - page * rowsPerPage);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        let nuberOfRowsPerPage = rowsPerPage

        getNextTagsAfterPagination(newPage, nuberOfRowsPerPage)
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);

        let Rows_per_page_here = parseInt(event.target.value, 10);
        let page_here = 0;
        getNextTagsAfterPagination(page_here, Rows_per_page_here)
    };

  const getNextTagsAfterPagination = (newPage, nuberOfRowsPerPage) => {
    console.log("new page number is", newPage, newPage + 1);
    console.log("nuberOfRowsPerPage in next", nuberOfRowsPerPage);
    // setShowProcedure(true);
    // setShowTableFlag(false);

    const requestOptions = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({'business_id':business_id,
          'device_token': device_token, 'session_id': session_id,
          'page': newPage + 1, 'per_page': nuberOfRowsPerPage
      })
    };
    fetch( process.env.REACT_APP_GET_BUSINESS_TAGS , requestOptions)
      .then((Response) => Response.json())
      .then(tagDetails => {
            console.log("tagDetails on same page",tagDetails,tagDetails.data.business_tags_info);
            if(tagDetails.data.business_tags_info)
            {
                settagList(tagDetails.data.business_tags_info)
            }
            if(tagDetails.data.pagination)
            {
              console.log("in pagination tagDetails.data.pagination", tagDetails.data.pagination)
              setpaginationDetails(tagDetails.data.pagination)
              setRowsPerPage(nuberOfRowsPerPage)
              setrecentPageNumberFromApi(tagDetails.data.pagination.current_page)
              settotalNumberOfPagesFromApi(tagDetails.data.pagination.total_pages)
            }
            if(tagDetails.success == false){
              settagList([])
              let total_tags_here = 0
              setpaginationDetails({...paginationDetails, total_items:total_tags_here })
              Swal.fire({
                icon: 'error',
                text: tagDetails.errors,
                confirmButtonColor: '#d33',
                confirmButtonText: 'OK',
                timer: 5000,
            })
            }
        })
        .catch(err => {
          Swal.fire({
            icon: 'error',
            // text: "Something went wrong",
            text: "Server Error. Please try again.",
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        });

  }


  useEffect(() => {
    setFlag(true);
    dispatch(userActions.checkSessionValidity());

    localStorage.removeItem('view_clients_active_cases');

    let user_permissions = JSON.parse(localStorage.getItem('user_permissions'))
    console.log("user_permissions from local storage on users page::::::",user_permissions)
    console.log("user_permissions from local storage on users page::::::",user_permissions.role_info.role_shortcode)
     setBusinessRole(user_permissions.role_info.role_shortcode)

     let per_page_here = 100;

     const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({'business_id':business_id,
            'device_token': device_token, 'session_id': session_id,
            'page': 1, 'per_page': per_page_here })
      };
      fetch( process.env.REACT_APP_GET_BUSINESS_TAGS , requestOptions)
        .then((Response) => Response.json())
        .then(tagDetails => {
            console.log("tagDetails on same page",tagDetails,tagDetails.data.business_tags_info);
            if(tagDetails.data.business_tags_info)
            {
                settagList(tagDetails.data.business_tags_info)
            }
            if(tagDetails.data.pagination)
            {
              console.log("in pagination tagDetails.data.pagination", tagDetails.data.pagination)
              setpaginationDetails(tagDetails.data.pagination)
              setRowsPerPage(per_page_here)
              setrecentPageNumberFromApi(tagDetails.data.pagination.current_page)
              settotalNumberOfPagesFromApi(tagDetails.data.pagination.total_pages)
            }
            if(tagDetails.success == false){
              settagList([])
              let total_tags_here = 0
              setpaginationDetails({...paginationDetails, total_items:total_tags_here })
              Swal.fire({
                icon: 'error',
                text: tagDetails.errors,
                confirmButtonColor: '#d33',
                confirmButtonText: 'OK',
                timer: 5000,
            })
            }
        })
        .catch(err => {
          Swal.fire({
            icon: 'error',
            // text: "Something went wrong",
            text: "Server Error. Please try again.",
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        });

  }, [Flag]);

  const handleClickEditTag = (item) => {
    console.log("handleClickEditTag is clicked", item)
    if( user_permissions && user_permissions.permissions.create_tags === true ){
      settagDetails({
          name: item.name,
          shortcode: item.shortcode,
          is_deleted: false,
          color: item.color,
          tag_id: item.tag_id,
          business_id: JSON.parse(localStorage.getItem('selected_business_id')),
          device_token: JSON.parse(localStorage.getItem('device_token')),
          session_id: JSON.parse(localStorage.getItem('session_id')),
      })

      setEditTag(true);
      setOpen(true);
    }
  }

  const handelCreateTagOpen = () => {
    setOpen(true);
    dispatch(userActions.checkSessionValidity());
  }

  const handelCreateTagClose = () => {
    setOpen(false);
    settagDetails({
      name: "",
      shortcode: "",
      is_deleted: false,
      color: "",
      business_id: JSON.parse(localStorage.getItem('selected_business_id')),
      device_token: JSON.parse(localStorage.getItem('device_token')),
      session_id: JSON.parse(localStorage.getItem('session_id')),
    })
    setnameErr();
    // setshortcodeErr();
    setisDeletedErr();
    setcolorErr();
    dispatch(userActions.checkSessionValidity());
    setEditTag(false);
  }

  const handelCreateTagClear = async() => {
    await settagDetails({
      name: "",
      shortcode: "",
      is_deleted: false,
      color: "",
      business_id: JSON.parse(localStorage.getItem('selected_business_id')),
      device_token: JSON.parse(localStorage.getItem('device_token')),
      session_id: JSON.parse(localStorage.getItem('session_id')),
    })
    setOpen(false);
    setOpen(true);
    setnameErr();
    setisDeletedErr();
    setcolorErr();
    dispatch(userActions.checkSessionValidity());
  }

  const handelCreateTagMinimize = () => {
    setOpen(false);
    dispatch(userActions.checkSessionValidity());
  }

  const validate = () => {
    let nameErr = '';
    let colorErr = '';

    if(!tagDetails.name)  {
        nameErr = 'Please Enter Name';
    }
    else{
      if(EditTag == false)
      {
        tagList.map((e,i)=>{
          if(e.name === tagDetails.name){
            nameErr = 'Please enter another name, this name is already in use.'
          }
        })
      }
      else if(EditTag == true){
        tagList.map((e,i)=>{
          if(e.name === tagDetails.name){
            if(e.tag_id === tagDetails.tag_id){
              console.log("same tag leave it")
            }
            else{
              nameErr = 'Please enter another name, this name is already in use.'
            }
          }
        })
      }
    }

    if (!tagDetails.color){
      colorErr = 'Please Select Color';
    }
    else{
      if(EditTag == false)
      {
        // console.log("color in validation =", tagDetails.color)
        tagList.map((e,i)=>{
          // console.log("element in validation tag list map is",e)
          if(e.color === tagDetails.color){
            colorErr = 'Please select another color, this color is already in use.'
          }
        })
      }
      else if(EditTag == true){
        // let count = 0;   
        tagList.map((e,i)=>{
          // console.log(" if it is edit then compare ",tagDetails,e)
          // console.log("element in validation tag list map is",e)
          if(e.color === tagDetails.color){
            if(e.tag_id === tagDetails.tag_id){
              console.log("same tag leave it")
            }
            else{
              colorErr = 'Please select another color, this color is already in use.'
            }
          }
        })
        // console.log("count is",count)
        // if(count >= 2){
        //   colorErr = 'Please select another color, this color is already in use.'
        // }
      }
       
    }



    if( nameErr || colorErr ){
      setnameErr(nameErr);
      setcolorErr(colorErr);

      return false;
    }
    return true;
  }

  const handelSubmitCreateTag = () => {
    console.log("tagDetails",tagDetails);
    dispatch(userActions.checkSessionValidity());
    const isValid = validate();
    if(isValid){
      setShowProcedure(true);
      setOpen(false);
      const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(tagDetails)
        };
        return fetch( process.env.REACT_APP_CREATE_TAG , requestOptions)
            .then((Response) => Response.json())
            .then(tagCreated => {
                setShowProcedure(false);
                console.log("tagCreated on same page",tagCreated.success);
                console.log("tagCreated on same page",tagCreated);
                if(tagCreated.success == true){
                  if(EditTag == true){
                    Swal.fire({
                      icon: 'success',
                      text: "Tag Updated Succesfully",
                      confirmButtonColor: 'primary',
                      confirmButtonText: 'OK',
                      timer: 5000,
                    })
                  }
                  else{
                    Swal.fire({
                      icon: 'success',
                      text: "Tag Created Succesfully",
                      confirmButtonColor: 'primary',
                      confirmButtonText: 'OK',
                      timer: 5000,
                    })
                  }
                  
                  settagDetails({
                    name: "",
                    shortcode: "",
                    is_deleted: false,
                    color: "",
                    business_id: JSON.parse(localStorage.getItem('selected_business_id')),
                    device_token: JSON.parse(localStorage.getItem('device_token')),
                    session_id: JSON.parse(localStorage.getItem('session_id')),
                  })
                  setnameErr();
                  setisDeletedErr();
                  setcolorErr();
                  setFlag(false);
                  setEditTag(false);
                }
                if(tagCreated.success == false){
                  Swal.fire({
                    icon: 'error',
                    text: tagCreated.errors,
                    confirmButtonColor: '#d33',
                    confirmButtonText: 'OK',
                    timer: 5000,
                  })
                  .then(function (result) {
                    console.log("result of swal",result)
                    setOpen(true);
                  })
              }
            })
            .catch(err => {
              setShowProcedure(false);
              Swal.fire({
                icon: 'error',
                // text: "Something went wrong",
                text: "Server Error. Please try again.",
                confirmButtonColor: '#d33',
                confirmButtonText: 'OK'
              })
            });
    }
  }

  const handleChangeColor = (color, event) => {
    console.log("color, event",color, event)
    settagDetails({...tagDetails, color:color.hex })
  }

  const body = (
    <div className={classes.paperModal}>
    <div className={classes.addNewBusinessHeaderBackgroud}> 
      <Grid container item xs={12}>
        <Grid item xs={10} >
            {
                ( EditTag == true )?
                (
                    <h2 style={{marginLeft:'50%'}}>Edit Tag</h2>
                ):
                (
                    <h2 style={{marginLeft:'50%'}}>Add Tag</h2>
                )
            }
        
        </Grid>
            {/* {
                ( EditTag == true )?
                (
                    <div></div>
                ):
                (
                    <Grid item xs={1} style={{marginTop:'0%',marginRight:'-1%', }}>
                        <MinimizeIcon onClick={()=>handelCreateTagMinimize()} style={{fontSize: 20, cursor:'pointer'}}/>
                    </Grid>
                )
            } */}
          
        
        <Grid item xs={1} 
            // style={ EditTag == false ? { marginTop:'1%'} : {marginTop:'1%', marginLeft:'6%'}}
            style={ { marginTop:'1%',  marginLeft:'7%'}}
        >
          <CloseIcon onClick={()=>handelCreateTagClose()} style={{fontSize: 20, cursor:'pointer'}}/>
        </Grid>
      </Grid>
    </div>
    <div className={classes.modalBody} style={{position:'absolute'}}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="name"
          label="Name"
          name="name"
          autoComplete="name"
          value={tagDetails.name}
          onChange={(e) => settagDetails({...tagDetails, name:e.target.value }) }
        />
        {/* <div className={classes.validation}>{(tagDetails.name)?(<div></div>):(nameErr)}</div> */}
        <div className={classes.validation} style={{marginTop:'2%'}}>{(nameErr)?(nameErr):(<div></div>)}</div>

        <FormControl className={classes.formControl}>
          <InputLabel id="color">Color *</InputLabel>
          <div style={{marginTop: "10%"}}>
          <SketchPicker 
            color={ tagDetails.color }
            onChangeComplete={ (color, event) => handleChangeColor(color, event) }
          />
          </div>
        </FormControl>
        {/* <div className={classes.validation}>{(tagDetails.color)?(<div></div>):(colorErr)}</div> */}
        <div className={classes.validation} style={{marginTop:'2%'}}>{(colorErr)?(colorErr):(<div></div>)}</div>

      <div style={{display: 'flex',alignItems: 'flex-end', justifyContent: 'flex-end', marginTop: '6%'}}>
            {
                ( EditTag == true )?
                (
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={handelSubmitCreateTag}
                    >
                        Update
                    </Button>
                ):
                (
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={handelSubmitCreateTag}
                    >
                        Add
                    </Button>
                )
            }
            
            <div style={{marginLeft:'2%'}}></div>
            <Button
                type="submit"
                variant="contained"
                className={classes.submit}
                onClick={handelCreateTagClear}
            >
                Clear
            </Button>
        </div>
    </div>
    </div>
)
 

return (
    //   console.log("userdetails in returns",userDetails),
      <div className={classes.root}>
          {ShowProcedure ? <modal className={classes.showProcedureStyle} isOpen={true}>
            <PropagateLoader  color={'#255ab0'} size='40px' loading='true' />
          </modal>: null}
          <div className={classes.wrapper}>
            <Grid container  className={classes.pageHeaderContainer}>
              <Grid item xs={10} className={classes.pageHeaderLabel}>
                Tags List
              </Grid>

              {user_permissions && user_permissions.permissions.create_tags &&
                <Grid item xs={2}>
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        startIcon={<AddIcon />}
                        onClick={() => handelCreateTagOpen()}
                    >
                        Add New Tag
                    </Button>
                </Grid>
              }

            </Grid>
            <Grid style={{marginTop:'-2%'}}>
                <TableContainer className={classes.tableWrapper} component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                    <EnhancedTableHead
                      classes={classes}
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={handleRequestSort}
                      // rowCount={tagList && tagList.length}
                      rowCount={paginationDetails && paginationDetails.total_items}
                    />
                    <TableBody>
                        {stableSort(tagList && tagList, getComparator(order, orderBy))
                        // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((item) => {
                          // console.log("row in table map",item)
                            return (
                            <TableRow 
                                key={item.tag_id}
                                hover={true} classes={{hover: classes.hover}}
                                tabIndex={-1}
                                align="center"
                            >
                                <TableCell width="10%" align="center" onClick={ () => handleClickEditTag(item)}>{item.tag_id}</TableCell>
                                <TableCell width="10%" align="center" onClick={ () => handleClickEditTag(item)}>{item.name}</TableCell> 
                                <TableCell width="10%" align="center" onClick={ () => handleClickEditTag(item)}>{item.shortcode}</TableCell> 
                                <TableCell width="10%" align="center" onClick={ () => handleClickEditTag(item)}>{item.color}</TableCell>
                                {/* {user_permissions && user_permissions.permissions.create_tags && */}
                                  <TableCell width="10%" align="center">
                                    <Button
                                      size="small"
                                      variant="contained"
                                      color="primary"
                                      className={classes.button}
                                      disabled= {(user_permissions && user_permissions.permissions.create_tags !== true ? true : null)}
                                      onClick={() => handleClickEditTag(item)}
                                    >
                                      Edit
                                    </Button>
                                  </TableCell>
                                {/* } */}

                            </TableRow>
                            );
                        })}
                            {/* {emptyRows > 0 && (
                            <TableRow style={{ height: 53 * emptyRows }}>
                                <TableCell colSpan={6} />
                            </TableRow>
                            )} */}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                            <TablePagination
                                // rowsPerPageOptions={[5, 10, 25, { label: 'All', value: tagList && tagList.length }]}
                                rowsPerPageOptions={[100, 50, 25, 10, { label: 'All', value: paginationDetails.total_items }]}
                                colSpan={8}
                                // count={tagList && tagList.length}
                                count={paginationDetails && paginationDetails.total_items}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                    inputProps: { 'aria-label': 'rows per page' },
                                    native: true,
                                }}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                            </TableRow>
                        </TableFooter>
                </Table>
                </TableContainer>
            </Grid>
            <Modal
                    open={open}
                    onClose={handelCreateTagClose}
                    className={classes.modal}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    {body}
                </Modal>
                   
          </div>
      </div>
    )
}