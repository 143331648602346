import React, { useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Modal from '@material-ui/core/Modal';
import { authHeader } from '../helpers';
import Swal from 'sweetalert2';
import FormControl from '@material-ui/core/FormControl';
import TextField from "@material-ui/core/TextField";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { PropagateLoader } from 'react-spinners';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import "react-phone-input-2/lib/material.css";
import { modal } from 'reactstrap';
import { useSelector , useDispatch} from 'react-redux';
import { userActions } from "../actions";
import useCheckSessionApi from "../SessionValidity";
import CloseIcon from '@material-ui/icons/Close';
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import AntSwitch from '@material-ui/core/Switch';
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import Paper from '@material-ui/core/Paper';
import PropTypes from "prop-types";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import Chip from "@material-ui/core/Chip";
import Avatar from "@material-ui/core/Avatar";
import MoreVertIcon from "@material-ui/icons/MoreVert";

import TableSortLabel from '@material-ui/core/TableSortLabel';
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(ContactNumberList, comparator) {
  const stabilizedThis = ContactNumberList && ContactNumberList.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: 'phone', numeric: false, label: 'Contact Number', },
  { id:'phone_type',numeric: false, label: 'Contact Number Type', },
  { id: 'is_active', numeric: false, label: 'Is Active', },
  { id: 'actions', numeric: false, label: 'Actions', },
];
function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          ( headCell.id === "actions")?
            (
              <TableCell
                key={headCell.id}
                align="center"
                style={{fontWeight: 'bold', fontSize: 17,  width: "1%"}}
              >
                {headCell.label}
              </TableCell>
            )
            :(
            <TableCell
              key={headCell.id}
              // align={headCell.numeric ? 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'default'}
              sortDirection={orderBy === headCell.id ? order : false}
              align="center"
              style={{fontWeight: 'bold', fontSize: 17,  width: "10%"}}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {/* {order === 'desc' ? 'sorted descending' : 'sorted ascending'} */}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
            )
          
        ))}
      </TableRow>
    </TableHead>
  );
}
EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  }
}));
function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}
TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
//   intl: PropTypes.shape.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    justifyContent: 'center'
  },
  wrapper: {
    margin: "10%",
    marginRight: "10%",
    marginTop: "8%",
    marginBottom: "3%",
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow:'scroll',
  },
  paper: {
    position: 'absolute',
    width: 500,
    // height: 650,
    // top:'2%',
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    boxShadow: theme.shadows[5],
    // padding: theme.spacing(2, 4, 3),
  },
  addNewBusinessHeaderBackgroud: {
    display: 'flex',
    alignItems: 'center', 
    justifyContent: 'center',
    backgroundColor: '#23c1f7',
    height:'10%',
  },
  modalBody: {
    padding: theme.spacing(2, 4, 3),  
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  validation: {
    color: 'red',
    fontSize: 12,
  },
  formControl: {
    // margin: theme.spacing(1),
    marginTop: '4%',
    width: '100%',
  },
  buttonSwalDelete: {
    background: "#1a1aff",
    height: 35,
    fontSize: 14,
    color: '#ffffff',
    // padding: 10,
    border: "1px solid #1a1aff",
    borderRadius: 5,
  },
  buttonSwalCancel: {
    background: "#1a1aff",
    height: 35,
    fontSize: 14,
    color: '#ffffff',
    // padding: 10,
    border: "1px solid #1a1aff",
    borderRadius: 5,
    marginRight: "2%",
  },
  showProcedureStyle: {
    position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)"
  },
  pageHeaderLabel:{
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "20px",
    lineHeight: "20px",
    color: "black",
    textAlign: "left",
  },
  buttonAddNew: {
    textAlign: "right",
    marginTop:"3%",
    marginLeft: "40%",
  },
  buttonDelete: {
    backgroundColor: "#de4545",
    color:"white",
    '&:hover': {
      backgroundColor: '#e85f5f',
      color: 'white',
    },
  },
  buttonShowHistory: {
    textAlign: "right",
    marginTop:"-9.6%",
    marginLeft: "45%",
  },
  backButton: {
    padding: 0,
    lineHeight: 0,
    textTransform: "none",
    color: "rgba(0, 0, 0, 0.64)",
    fontSize: "16px",
    fontWeight: 600,
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
  },
  backArrow: {
    marginRight: "10%",
  },

}));

export default function UserPhoneNumberDetails() {
    const classes = useStyles();
    // const [ContactNumberList, setContactNumberList] = React.useState([
    //   { id: 1, number: "1234567890"},
    //   { id: 2, number: "1122334455"},
    //   { id: 3, number: "6677889900"},
    // ]);
    const [ ShowProcedure, setShowProcedure ] = React.useState(false);
    const [device_token, setdevice_token] = React.useState(JSON.parse(localStorage.getItem('device_token')));
    const [session_id, setsession_id] = React.useState(JSON.parse(localStorage.getItem('session_id')));
    const [flag , setflag] = React.useState(false);
    const [ContactNumberList, setContactNumberList] = React.useState([]);
    const [newPhoneNumberDetails, setnewPhoneNumberDetails] = React.useState({
      phone: "",
      country_code: "",
      phone_type: "",
      is_active: "",
      device_token: JSON.parse(localStorage.getItem('device_token')),
      session_id: JSON.parse(localStorage.getItem('session_id')), 
    });
    const [CountryCode, setCountryCode] = React.useState("");
    const [onlyCountryArray, setonlyCountryArray] = React.useState([
      'in','us', 'gb', 'ca', 'ae', 'sg','np', 'jp', 'de', 'bt', 'au'
    ]);
    const [yesNo, setyesNo] = React.useState([
      { value: true , name: 'Yes'  },
      { value: false , name: 'No'  },
    ]);
    const [phoneNumberErr, setphoneNumberErr] = React.useState("");
    const [isActiveErr, setisActiveErr] = React.useState("");
    const [phoneTypeErr, setphoneTypeErr] = React.useState("");

    const [openAddNewContact, setopenAddNewContact] = React.useState(false);

    const [openEditContact, setopenEditContact] = React.useState(false);
    const [editPhoneNumberDetails, seteditPhoneNumberDetails] = React.useState({
      phone_id: "",
      phone: "",
      is_active: "",
      device_token: JSON.parse(localStorage.getItem('device_token')),
      session_id: JSON.parse(localStorage.getItem('session_id')), 
    });

    const [deletePhoneNumberDetails, setdeletePhoneNumberDetails] = React.useState({
      phone_id: "",
      phone: "",
      is_active: "",
      device_token: JSON.parse(localStorage.getItem('device_token')),
      session_id: JSON.parse(localStorage.getItem('session_id')), 
    });

    const [openShowHistory, setopenShowHistory] = React.useState(false);
    const [clientPhoneLogDetails, setclientPhoneLogDetails] = React.useState();

    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [page, setPage] = React.useState(0);

    const dispatch = useDispatch();

    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [selected, setSelected] = React.useState([]);
    const [dense, setDense] = React.useState(false);

    // sort
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    // pagination
    const emptyRows =
        rowsPerPage - Math.min(rowsPerPage, ContactNumberList && ContactNumberList.length - page * rowsPerPage);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };

    useEffect(() => {
      setflag(true);
      dispatch(userActions.checkSessionValidity());

      const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({'device_token': device_token, 'session_id': session_id })
      };
      fetch( process.env.REACT_APP_GET_USER_PHONE_NUMBERS , requestOptions)
        .then((Response) => Response.json())
        .then(phoneNumberDetails => {
          console.log("phoneNumberDetails are",phoneNumberDetails);
          if(phoneNumberDetails.data.phonenumbers)
          {
            let phoneNumber = phoneNumberDetails.data.phonenumbers
            let phoneNumberLog = phoneNumberDetails.data.phonenumbers_log
            let phoneNumberListMerger = [...phoneNumber, ...phoneNumberLog]
            setContactNumberList(phoneNumberListMerger)
          }
          if(phoneNumberDetails.success == false){
            setContactNumberList([])
            Swal.fire({
              icon: 'error',
              text: phoneNumberDetails.errors,
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK',
              timer: 5000,
            })
          }
      })
      .catch(err => {
        Swal.fire({
          icon: 'error',
          // text: "Something went wrong",
          text: "Server Error. Please try again.",
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      });

      // const requestOptionsLog = {
      //   method: 'POST',
      //   headers: authHeader(),
      //   body: JSON.stringify({client_id: "87", 'device_token': device_token, 'session_id': session_id })
      // };
      // fetch( process.env.REACT_APP_GET_CLIENT_DETAILS , requestOptionsLog)
      //   .then((Response) => Response.json())
      //   .then(clientDetails => {
      //     console.log("clientDetails are",clientDetails);
      //     if(clientDetails.data.client_details.phone_numbers)
      //     {
      //       setclientPhoneLogDetails(clientDetails.data.client_details.phone_numbers.phonenumbers_log)
      //     }
      //     if(clientDetails.success == false){
      //       setclientPhoneLogDetails([])
      //       Swal.fire({
      //         icon: 'error',
      //         text: clientDetails.errors,
      //         confirmButtonColor: '#d33',
      //         confirmButtonText: 'OK',
      //         timer: 5000,
      //       })
      //     }
      // })
      // .catch(err => {
      //   // console.log("err in client details catch",err),
      //   Swal.fire({
      //     icon: 'error',
      //     // text: "Something went wrong",
      //     text: "Server Error. Please try again.",
      //     // text: err,
      //     confirmButtonColor: '#d33',
      //     confirmButtonText: 'OK'
      //   })
      // });

    }, [flag]);

    // const readItemFromStorage = async() => {
    //   try {
    //     setDeviceToken(deviceTokenFromRedux)
    //     setSessionId(sessionIdFromRedux)
    //     setflag(false)    
    //   } catch (error) {
    //     console.log("ERROR:",error);        }
    // };

    // Add New Contact functions
    const handelAddNewContactOpen = () =>{
      setopenAddNewContact(true)
    }

    const handelAddNewContactClose = () =>{
      setopenAddNewContact(false);
      setnewPhoneNumberDetails({
        phone: "",
        country_code: "",
        phone_type: "",
        is_active: "",
        device_token: JSON.parse(localStorage.getItem('device_token')),
        session_id: JSON.parse(localStorage.getItem('session_id')), 
      });
      setphoneNumberErr("");
      setisActiveErr("");
      setphoneTypeErr("");
    }

    const validateAddNewPhoneNumber = () => {
      let phoneNumberErr = '';
      let isActiveErr = '';
      let phoneTypeErr = '';

      if(!newPhoneNumberDetails.phone)  {
          phoneNumberErr = 'Please Enter Phone Number';
      }
      else if (typeof newPhoneNumberDetails.phone !== "undefined") {
        var pattern = new RegExp(/^[0-9\b]+$/)

        // if (!pattern.test(newPhoneNumberDetails.phone)) {
        //   // isValid = false;
        //   phoneNumberErr = "Please enter only numbers.";
        // }else if(newPhoneNumberDetails.phone.length <= 10){
        //   // isValid = false;
        //   phoneNumberErr = "Please enter valid phone number.";
        // }
        if (CountryCode == "in"){
          // if(newPhoneNumberDetails.phone.length !== 15){
            if(newPhoneNumberDetails.phone.length !== 10){
              phoneNumberErr = "Please enter valid phone number.";
            }
        }
        else if(CountryCode == "us"){
          // if(newPhoneNumberDetails.phone.length !== 17){
          if(newPhoneNumberDetails.phone.length !== 10){
              phoneNumberErr = "Please enter valid phone number.";
            }
        }
        else if(CountryCode == "gb"){
          console.log("newPhoneNumberDetails.phone.length gb",newPhoneNumberDetails.phone.length)
          if(newPhoneNumberDetails.phone.length !== 10){
            phoneNumberErr = "Please enter valid phone number.";
          }
        }
        else if(CountryCode == "ca"){
          console.log("newPhoneNumberDetails.phone.length gb",newPhoneNumberDetails.phone.length)
          if(newPhoneNumberDetails.phone.length !== 10){
            phoneNumberErr = "Please enter valid phone number.";
          }
        }
        else if(CountryCode == "ae"){
          console.log("newPhoneNumberDetails.phone.length gb",newPhoneNumberDetails.phone.length)
          if(newPhoneNumberDetails.phone.length !== 12){
            phoneNumberErr = "Please enter valid phone number.";
          }
        }
        else if(CountryCode == "sg"){
          console.log("newPhoneNumberDetails.phone.length gb",newPhoneNumberDetails.phone.length)
          if(newPhoneNumberDetails.phone.length !== 8){
            phoneNumberErr = "Please enter valid phone number.";
          }
        }
        else if(CountryCode == "np"){
          console.log("newPhoneNumberDetails.phone.length gb",newPhoneNumberDetails.phone.length)
          if(newPhoneNumberDetails.phone.length !== 12){
            phoneNumberErr = "Please enter valid phone number.";
          }
        }
        else if(CountryCode == "jp"){
          // console.log("clientDetails.phone.length gb",newPhoneNumberDetails.phone.length)
          if(newPhoneNumberDetails.phone.length !== 10){
            phoneNumberErr = "Please enter valid phone number.";
          }
        }
        else if(CountryCode == "de"){
          // console.log("clientDetails.phone.length gb",clientDetails.phone.length)
          if(newPhoneNumberDetails.phone.length !== 12){
            phoneNumberErr = "Please enter valid phone number.";
          }
        }
        else if(CountryCode == "bt"){
          // console.log("clientDetails.phone.length gb",clientDetails.phone.length)
          if(newPhoneNumberDetails.phone.length !== 12){
            phoneNumberErr = "Please enter valid phone number.";
          }
        }
        else if(CountryCode == "au"){
          // console.log("clientDetails.phone.length gb",clientDetails.phone.length)
          if(newPhoneNumberDetails.phone.length !== 13){
            phoneNumberErr = "Please enter valid phone number.";
          }
        }
      }
      if(!newPhoneNumberDetails.phone_type)  {
        phoneTypeErr = 'Please Select Phone Type';
      }
      

      if(newPhoneNumberDetails.is_active == "" &&  newPhoneNumberDetails.is_active !== false  && newPhoneNumberDetails.is_active !== true ){
          isActiveErr = 'Please Select Is Active';
      }

      if(phoneNumberErr || isActiveErr || phoneTypeErr ){
        setphoneNumberErr(phoneNumberErr);
        setisActiveErr(isActiveErr);
        setphoneTypeErr(phoneTypeErr);
        return false;
      }
      return true;
    }

    const handelAddContactSubmit = () => {
      console.log("Add Contact Clicked",newPhoneNumberDetails)
      const isValid = validateAddNewPhoneNumber();
      if(isValid){
        setShowProcedure(true);
        const requestOptions = {
          method: 'POST',
          headers: authHeader(),
          body: JSON.stringify(newPhoneNumberDetails)
        };
        return fetch( process.env.REACT_APP_CREATE_PHONE_NUMBER , requestOptions)
          .then((Response) => Response.json())
          .then(phoneAdded => {
            setShowProcedure(false);
            console.log("phoneAdded",phoneAdded.success);
            console.log("phoneAdded",phoneAdded);
            if(phoneAdded.success == true){
              Swal.fire({
                icon: 'success',
                text: "Phone Number Added Succesfully",
                confirmButtonColor: 'primary',
                confirmButtonText: 'OK',
                timer: 5000,
              })
              setopenAddNewContact(false);
              setnewPhoneNumberDetails({
                phone: "",
                phone_type: "",
                country_code: "",
                is_active: "",
                device_token: JSON.parse(localStorage.getItem('device_token')),
                session_id: JSON.parse(localStorage.getItem('session_id')), 
              });
              setphoneNumberErr("");
              setisActiveErr("");
              setphoneTypeErr("");
            }
            if(phoneAdded.success == false ){
              setopenAddNewContact(true);
              Swal.fire({
                icon: 'error',
                text: phoneAdded.errors,
                confirmButtonColor: '#d33',
                confirmButtonText: 'OK',
                // timer: 5000,
              })
            } 
          setflag(false);            
        })
        .catch(err => {
          setShowProcedure(false);
          Swal.fire({
            icon: 'error',
            // text: "Something went wrong",
            text: "Server Error. Please try again.",
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        });
      }
    }

    // const phoneNumberChange = (usersNumber,e) => {
    //   // console.log("phoneNumberChange usersNumber",usersNumber)
    //   // console.log("phoneNumberChange e",e.countryCode)
    //   setCountryCode(e.countryCode)
    //   setnewPhoneNumberDetails({...newPhoneNumberDetails, phone:usersNumber })
    // }
    const phoneNumberChange = (clientNumber,e,formattedValue,value) => {
      console.log("phoneNumberChange clientNumber",clientNumber)
      console.log("phoneNumberChange e",e)
      console.log("phoneNumberChange value",value)
      let countryCodeIs = "+" + e.dialCode
      let splitNoIs = ""
      if(e.countryCode == "in"){
        splitNoIs = value.split(" ")[1];
      }
      if(e.countryCode == "us"){
        // splitNoIs = value.split(" ")[1];
        // splitNoIs = value.substr(value.indexOf(' ')+1);

        splitNoIs = value.split(" ")[1];
      }
      if(e.countryCode == "gb"){
        splitNoIs = value.split(" ")[1];
      }
      if(e.countryCode == "ca"){
        splitNoIs = value.split(" ")[1];
      }
      if(e.countryCode == "ae"){
        splitNoIs = value.split(" ")[1];
      }
      if(e.countryCode == "sg"){
        splitNoIs = value.split(" ")[1];
      }
      if(e.countryCode == "np"){
        splitNoIs = value.split(" ")[1];
      }
      if(e.countryCode == "jp"){
        splitNoIs = value.split(" ")[1];
      }
      if(e.countryCode == "de"){
        splitNoIs = value.split(" ")[1];
      }
      if(e.countryCode == "bt"){
        splitNoIs = value.split(" ")[1];
      }
      if(e.countryCode == "au"){
        splitNoIs = value.split(" ")[1];
      }
      console.log("splitNoIs",splitNoIs)
      let noHereIs = "+"+e.dialCode+"-"+splitNoIs;
      console.log("noHereIs",noHereIs)
      setCountryCode(e.countryCode)
      // setnewPhoneNumberDetails({...newPhoneNumberDetails, phone:noHereIs })
      setnewPhoneNumberDetails({...newPhoneNumberDetails, phone: splitNoIs, country_code: countryCodeIs })
    }

    const bodyAddNewContact = (
      <div className={classes.paper}>
      <div className={classes.addNewBusinessHeaderBackgroud}> 
        <h2>Add New Contact</h2>
      </div>
      <div className={classes.modalBody}>
        {/* <TextField
          margin="normal"
          required
          fullWidth
          id="phone"
          label="Phone Number"
          name="phone"
          autoComplete="phone"
          value={newPhoneNumberDetails.phone}
          onChange={(e) => setnewPhoneNumberDetails({...newPhoneNumberDetails, phone:e.target.value }) }
        />
        <div className={classes.validation}>{(newPhoneNumberDetails.phone)?(<div></div>):(phoneNumberErr)}</div> */}

        {/* <InputLabel id="is_active">Phone Number*</InputLabel> */}
        <Grid item xs={12} className={classes.subHeadingLabel}>
          Phone Number
        </Grid>
        <Grid item xs={12}>
        <PhoneInput
          inputStyle={{
            width: "100%",
            height: "50px",
            fontSize: "15px",
          }}
          countryCodeEditable={false}
          onlyCountries={onlyCountryArray}
          specialLabel=""
          country={'in'}
          masks={{in: '..........', us: '..........', gb: '..........', ca: '..........', ae:'............',
                    sg: '........', np:'............', jp:'..........', de:'............', bt:'............',
                    au: '.............'  }}
          // value={newPhoneNumberDetails.phone}
          value={newPhoneNumberDetails.country_code + newPhoneNumberDetails.phone}
          // onChange={(usersNumber) => setnewPhoneNumberDetails({...newPhoneNumberDetails, phone:usersNumber })}
          onChange={(usersNumber,e,formattedValue,value) => phoneNumberChange(usersNumber,e,formattedValue,value)}
        />
        <div className={classes.validation}>{phoneNumberErr}</div>
        </Grid>

        <FormControl className={classes.formControl}>
          <InputLabel id="phone_type">Phone Type*</InputLabel>
          <Select
            labelId="phone_type"
            id="phone_type"
            value={newPhoneNumberDetails.phone_type}
            onChange={(e) => setnewPhoneNumberDetails({...newPhoneNumberDetails, phone_type:e.target.value }) }
          >
              <MenuItem value="primary">Primary</MenuItem>
              <MenuItem value="secondary">Secondary</MenuItem>
              <MenuItem value="emergency">Emergency</MenuItem>
              <MenuItem value="other">Other</MenuItem>
          </Select>
        </FormControl>
        <div className={classes.validation}>{(newPhoneNumberDetails.is_active)?(<div></div>):(phoneTypeErr)}</div>

        <FormControl className={classes.formControl}>
          <InputLabel id="is_active">Is Active*</InputLabel>
          <Grid container item xs={12} style={{marginTop:"8%"}}>
            <RadioGroup
              row
              id ="is_active"
              aria-label="is_active"
              name="is_active"
              defaultValue={JSON.stringify(newPhoneNumberDetails.is_active)}
              onChange={ (e) => setnewPhoneNumberDetails({...newPhoneNumberDetails, is_active: JSON.parse(e.target.value) })}
            >                  
              <div>
                <FormControlLabel
                  value="true"
                  classes={{ label: classes.label }}
                  control={<Radio size="small" color="primary" />}
                  label= "Yes"
                />
              </div>
              <div>
                <FormControlLabel
                  value="false"
                  classes={{ label: classes.label }}
                  control={<Radio size="small" color="primary" />}
                  label= "No"
                />
              </div>
            </RadioGroup>
          </Grid>
          {/* <Select
            labelId="is_active"
            id="is_active"
            value={newPhoneNumberDetails.is_active}
            onChange={(e) => setnewPhoneNumberDetails({...newPhoneNumberDetails, is_active:e.target.value }) }
          >
            {yesNo.map(item =>
              <MenuItem value={item.value}>{item.name}</MenuItem>
            )}
          </Select> */}
        </FormControl>
        <div className={classes.validation}>{(newPhoneNumberDetails.is_active == "" &&  newPhoneNumberDetails.is_active !== false  && newPhoneNumberDetails.is_active !== true)?(isActiveErr):(<div></div>)}</div>

        <div style={{display: 'flex',alignItems: 'flex-end', justifyContent: 'flex-end',}}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handelAddContactSubmit}
          >
            Add Contact
          </Button>
          <div style={{marginLeft:'2%'}}></div>
          <Button
            type="submit"
            variant="contained"
            className={classes.submit}
            onClick={handelAddNewContactClose}
          >
            Cancel
          </Button>
        </div>
      </div>
      </div>
    )

    // Edit Contact Functions
    const handleEditContact = async(item) => {
      console.log("item",item)
      await seteditPhoneNumberDetails({
        phone_id: item.phone_id,
        phone: item.phone,
        is_active: item.is_active,
        device_token: JSON.parse(localStorage.getItem('device_token')),
        session_id: JSON.parse(localStorage.getItem('session_id')),  
      });
      console.log("editPhoneNumberDetails",editPhoneNumberDetails);
      handleEditContactOpen();
    }

    const handleEditContactOpen = () => {
      setopenEditContact(true);
    }

    const handleEditContactClose = () => {
      setopenEditContact(false);
      // setphoneNumberEditErr("");
      // setisActiveEditErr("");
    }

    const handelEditContactSubmit = () => {
      setShowProcedure(true);
      console.log("Edit contact clicked",editPhoneNumberDetails);
      // const isValid = validateAddNewPhoneNumber();
      // if(isValid){
        const requestOptions = {
          method: 'POST',
          headers: authHeader(),
          body: JSON.stringify(editPhoneNumberDetails)
        };
        return fetch( process.env.REACT_APP_CREATE_PHONE_NUMBER , requestOptions)
          .then((Response) => Response.json())
          .then(phoneEdited => {
            setShowProcedure(false);
            console.log("phoneEdited",phoneEdited.success);
            console.log("phoneEdited",phoneEdited);
            if(phoneEdited.success == true){
              Swal.fire({
                icon: 'success',
                text: "Phone Number Edited Succesfully",
                confirmButtonColor: 'primary',
                confirmButtonText: 'OK',
                timer: 5000,
              })
              setopenEditContact(false);
              seteditPhoneNumberDetails({
                phone_id: "",
                phone: "",
                is_active: "",
                device_token: JSON.parse(localStorage.getItem('device_token')),
                session_id: JSON.parse(localStorage.getItem('session_id')), 
              });
              // setphoneNumberEditErr("");
              // setisActiveEditErr("");
            }
            if(phoneEdited.success == false ){
              setopenEditContact(true);
              Swal.fire({
                icon: 'error',
                text: phoneEdited.errors,
                confirmButtonColor: '#d33',
                confirmButtonText: 'OK',
                // timer: 5000,
              })
            } 
          setflag(false);            
        })
        .catch(err => {
          setShowProcedure(false);
          Swal.fire({
            icon: 'error',
            // text: "Something went wrong",
            text: "Server Error. Please try again.",
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        });
      // }
    }

    // const handleDeletePhoneNumber = async(item) => {
    //   setShowProcedure(true);
    //   console.log("in delete",item);
    //   let idtoDelete =  item.phone_id;
    //   await setdeletePhoneNumberDetails({
    //     phone_id: item.phone_id,
    //     phone: item.phone,
    //     phone_type: item.phone_type,
    //     is_active: !item.is_active,
    //     device_token: JSON.parse(localStorage.getItem('device_token')),
    //     session_id: JSON.parse(localStorage.getItem('session_id')), 
    //   });
    //   console.log("deletePhoneNumberDetails",deletePhoneNumberDetails);

    //   let deletePhoneNumberDetailsFinal = ({
    //     phone_id: item.phone_id,
    //     phone: item.phone,
    //     phone_type: item.phone_type,
    //     is_active: !item.is_active,
    //     device_token: JSON.parse(localStorage.getItem('device_token')),
    //     session_id: JSON.parse(localStorage.getItem('session_id')), 
    //   });
    //   console.log("deletePhoneNumberDetailsFinal",deletePhoneNumberDetailsFinal);
        
    //   const swalWithBootstrapButtons = Swal.mixin({
    //     customClass: {
    //       confirmButton: classes.buttonSwalDelete,
    //       cancelButton: classes.buttonSwalCancel,
    //     },
    //     buttonsStyling: false
    //   })
    //   swalWithBootstrapButtons.fire({
    //   title: 'Are you sure?',
    //   text: "You won't be able to revert this!",
    //   icon: 'warning',
    //   showCancelButton: true,
    //   confirmButtonColor: '#d33',
    //   confirmButtonText: 'Yes, ',
    //   cancelButtonText: 'No, cancel',
    //   reverseButtons: true
    // }).then((result) => {

    //   if (result.value) {
    //     const requestOptions = {
    //       method: 'POST',
    //       headers: authHeader(),
    //       body: JSON.stringify(deletePhoneNumberDetailsFinal)
    //     };
    //     return fetch( process.env.REACT_APP_CREATE_PHONE_NUMBER , requestOptions)
    //       .then((Response) => Response.json())
    //       .then( phoneDeleted => {
    //         setShowProcedure(false);
    //         console.log("phoneDeleted",phoneDeleted.success);
    //         console.log("phoneDeleted",phoneDeleted);
    //         if(phoneDeleted.success == true){
    //           Swal.fire({
    //             icon: 'success',
    //             text: "Data Updated Succesfully",
    //             confirmButtonColor: 'primary',
    //             confirmButtonText: 'OK',
    //             timer: 5000,
    //           })
    //           setdeletePhoneNumberDetails({
    //             phone_id: "",
    //             phone: "",
    //             is_active: false,
    //             device_token: JSON.parse(localStorage.getItem('device_token')),
    //             session_id: JSON.parse(localStorage.getItem('session_id')), 
    //           });
    //         }
    //         if(phoneDeleted.success == false ){
    //           Swal.fire({
    //             icon: 'error',
    //             text: phoneDeleted.errors,
    //             confirmButtonColor: '#d33',
    //             confirmButtonText: 'OK',
    //             // timer: 5000,
    //           })
    //         } 
    //       setflag(false);            
    //     })
    //     .catch(err => {
    //       setShowProcedure(false);
    //       Swal.fire({
    //         icon: 'error',
    //         // text: "Something went wrong",
    //         text: "Server Error. Please try again.",
    //         confirmButtonColor: '#d33',
    //         confirmButtonText: 'OK'
    //       })
    //     });
              
    //   } else if (
    //     /* Read more about handling dismissals below */
    //     result.dismiss === Swal.DismissReason.cancel
    //   ) 
    //   {
    //     // swalWithBootstrapButtons.fire(
    //     //   'Cancelled',
    //     //   'Your imaginary file is safe :)',
    //     //   'error'
    //     // )
    //   }
    // })
    // }

    const handleDeletePhoneNumber = async(item) => {
      setShowProcedure(true);
      console.log("in delete",item);
      let idtoDelete =  item.phone_id;
      await setdeletePhoneNumberDetails({
        phone_id: item.phone_id,
        phone: item.phone,
        country_code: item.country_code,
        phone_type: item.phone_type,
        is_active: !item.is_active,
        device_token: JSON.parse(localStorage.getItem('device_token')),
        session_id: JSON.parse(localStorage.getItem('session_id')), 
      });
      console.log("deletePhoneNumberDetails",deletePhoneNumberDetails);

      let deletePhoneNumberDetailsFinal = ({
        phone_id: item.phone_id,
        phone: item.phone,
        country_code: item.country_code,
        phone_type: item.phone_type,
        is_active: !item.is_active,
        device_token: JSON.parse(localStorage.getItem('device_token')),
        session_id: JSON.parse(localStorage.getItem('session_id')), 
      });
      console.log("deletePhoneNumberDetailsFinal",deletePhoneNumberDetailsFinal);

        const requestOptions = {
          method: 'POST',
          headers: authHeader(),
          body: JSON.stringify(deletePhoneNumberDetailsFinal)
        };
        return fetch( process.env.REACT_APP_CREATE_PHONE_NUMBER , requestOptions)
          .then((Response) => Response.json())
          .then( phoneDeleted => {
            setShowProcedure(false);
            
            console.log("phoneDeleted",phoneDeleted);
            if(phoneDeleted.success == true){
              // Swal.fire({
              //   icon: 'success',
              //   text: "Data Updated Succesfully",
              //   confirmButtonColor: 'primary',
              //   confirmButtonText: 'OK',
              //   timer: 5000,
              // })
              console.log("phoneDeleted success",phoneDeleted);
              setdeletePhoneNumberDetails({
                phone_id: "",
                phone: "",
                is_active: false,
                device_token: JSON.parse(localStorage.getItem('device_token')),
                session_id: JSON.parse(localStorage.getItem('session_id')), 
              });
            }
            if(phoneDeleted.success == false ){
              Swal.fire({
                icon: 'error',
                text: phoneDeleted.errors,
                confirmButtonColor: '#d33',
                confirmButtonText: 'OK',
                // timer: 5000,
              })
            } 
          setflag(false);            
        })
        .catch(err => {
          setShowProcedure(false);
          Swal.fire({
            icon: 'error',
            // text: "Something went wrong",
            text: "Server Error. Please try again.",
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        });
    }

    const bodyEditContact = (
      <div className={classes.paper}>
      <div className={classes.addNewBusinessHeaderBackgroud}> 
        <h2>Edit Contact</h2>
      </div>
      <div className={classes.modalBody}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="phone"
          label="Phone Number"
          name="phone"
          autoComplete="phone"
          value={editPhoneNumberDetails.phone}
          onChange={(e) => seteditPhoneNumberDetails({...editPhoneNumberDetails, phone:e.target.value }) }
        />
        {/* <div className={classes.validation}>{phoneNumberEditErr}</div> */}

        <FormControl className={classes.formControl}>
          <InputLabel id="is_active">Is Active*</InputLabel>
          <Select
            labelId="is_active"
            id="is_active"
            value={editPhoneNumberDetails.is_active}
            onChange={(e) => seteditPhoneNumberDetails({...editPhoneNumberDetails, is_active:e.target.value }) }
          >
            {yesNo.map(item =>
              <MenuItem value={item.value}>{item.name}</MenuItem>
            )}
          </Select>
        </FormControl>
        {/* <div className={classes.validation}>{(editPhoneNumberDetails.is_active)?(<div></div>):(isActiveEditErr)}</div> */}

        <div style={{display: 'flex',alignItems: 'flex-end', justifyContent: 'flex-end',}}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handelEditContactSubmit}
          >
            Update Contact
          </Button>
          <div style={{marginLeft:'2%'}}></div>
          <Button
            type="submit"
            variant="contained"
            className={classes.submit}
            onClick={handleEditContactClose}
          >
            Cancel
          </Button>
        </div>
      </div>
      </div>
    )

    // history
  const handleShowHistoryModalOpen = () => {
    setopenShowHistory(true)
  }

  const handleShowHistoryModalClose = () => {
    setopenShowHistory(false)
  }

  const bodyshowHistory = (
    <div className={classes.paper}>
      <div > 
        <Grid container item xs={12}>
          <Grid item xs={10} style={{marginLeft:'5%'}}>
            <h2>Phone Numbers History</h2>
          </Grid>
          <Grid item xs={1} style={{marginTop:'3%', marginLeft:'1%'}}>
            <CloseIcon onClick={()=>handleShowHistoryModalClose()} style={{cursor:'pointer'}}/>
          </Grid>
        </Grid>
      </div>
      <div className={classes.modalBody}>
        {
          clientPhoneLogDetails && clientPhoneLogDetails.map((e, i)=>(
            <Grid style={{backgroundColor:'#bfd3e0'}}>
              <Grid style={{padding: 10}}>{e.phone}</Grid>
            </Grid>
          ))
        }

        <div style={{display: 'flex',alignItems: 'flex-end', justifyContent: 'flex-end',}}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handleShowHistoryModalClose}
          >
            Ok
          </Button>
        </div>
      </div>
      </div>
  )
    
    return(
      console.log("clientPhoneLogDetails",clientPhoneLogDetails),
        <div className={classes.root}>
         {ShowProcedure ? <modal className={classes.showProcedureStyle} isOpen={true}>
            <PropagateLoader color={'#255ab0'} size='40px' loading='true' />
          </modal>: null}
        <div className={classes.wrapper}>
            {/* <div>Address Details</div> */}
            <Grid container>
              <Grid item xs={1} className={classes.pageHeaderLabel} >
                <Button href="/UsersProfile" className={classes.backButton}>
                    <span className={classes.backArrow}>
                    <ArrowBackIcon fontSize="small" />
                  </span>
                  Back
                </Button>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={8} className={classes.pageHeaderLabel} >
                <h2>List of Phone Numbers</h2>
              </Grid>
              <Grid item xs={4} className={classes.AddNewBusinessLabel}>
                {/* <h2>Add New Business</h2> */}
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.buttonAddNew}
                  startIcon={<AddIcon />}
                  // href="/newclient"
                  onClick={handelAddNewContactOpen}
                >
                  Add New Number
                </Button>
              </Grid>
              <Modal
                open={openAddNewContact}
                onClose={handelAddNewContactClose}
                className={classes.modal}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
              >
                {bodyAddNewContact}
              </Modal>
            </Grid>
            <Grid item xs={12} >
              {/* <Button
                variant="contained"
                color="primary"
                className={classes.buttonShowHistory}
                onClick={handleShowHistoryModalOpen}
              >
                History
              </Button> */}
              {/* show history modal */}
              {/* <Modal
                open={openShowHistory}
                onClose={handleShowHistoryModalClose}
                className={classes.modal}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
              >
                {bodyshowHistory}
              </Modal> */}
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <TableContainer className={classes.tableWrapper} component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                    <EnhancedTableHead
                      classes={classes}
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={handleRequestSort}
                      rowCount={ContactNumberList && ContactNumberList.length}
                    />
                    <TableBody>
                        {stableSort(ContactNumberList && ContactNumberList, getComparator(order, orderBy))
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        // .filter(item => item.is_active == true ).map((ContactNumberList) => {
                          .map((ContactNumberList) => {
                          // console.log("row in table map",ContactNumberList)
                            return (
                            <TableRow 
                                key={ContactNumberList.phone_id} 
                                hover={true} classes={{hover: classes.hover}}
                                tabIndex={-1}
                                align="center"
                            >
                              {/* <TableCell width="10%" align="center">{ContactNumberList.phone}</TableCell> */}
                              <TableCell width="10%" align="center">{ContactNumberList.country_code + - + ContactNumberList.phone}</TableCell>
                              <TableCell width="10%" align="center">{ContactNumberList.phone_type}</TableCell>
                              {/* <TableCell width="10%" align="center">{ContactNumberList.is_active ? 'True' : 'false'}</TableCell> */}
                              <TableCell width="10%" align="center">{ContactNumberList.is_active ? 'Yes' : 'No'}</TableCell>
                              <TableCell width="5%" align="center" >
                                {/* <Button
                                  size="small"
                                  variant="contained"
                                  color="primary"
                                  className={classes.button}
                                  onClick={() => handleEditContact(ContactNumberList)}
                                  >
                                  Edit
                                </Button> */}
                                {/* <Button
                                  size="small"
                                  variant="contained"
                                  color="secondary"
                                  className={classes.buttonDelete}
                                  onClick={() => handleDeletePhoneNumber(ContactNumberList)}
                                  >
                                  Delete
                                </Button> */}
                                <AntSwitch 
                                  checked={ContactNumberList.is_active} 
                                  onChange={() => handleDeletePhoneNumber(ContactNumberList)} 
                                  size="small" 
                                  name="item.is_active" 
                                  value="item.is_active"
                                  color="primary"
                                />
                              </TableCell>
                            </TableRow>
                            );
                        })}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: ContactNumberList && ContactNumberList.length }]}
                                colSpan={8}
                                count={ContactNumberList && ContactNumberList.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                    inputProps: { 'aria-label': 'rows per page' },
                                    native: true,
                                }}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                            </TableRow>
                        </TableFooter>
                </Table>
                </TableContainer>
              </Grid>
            </Grid>
            <Modal
              open={openEditContact}
              onClose={handleEditContactClose}
              className={classes.modal}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
            >
              {bodyEditContact}
            </Modal>
        </div>  
        </div>
    )
}